import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/[locale]/CompareButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/[locale]/LanguageSelector.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/[locale]/PinnedJobsContextProvider.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/CookiePopup.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/ToastProvider.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"app/[locale]/layout.tsx\",\"import\":\"Merriweather\",\"arguments\":[{\"variable\":\"--font-merriweather\",\"subsets\":[\"latin\"],\"weight\":[\"300\",\"400\",\"700\"]}],\"variableName\":\"merriweather\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"app/[locale]/layout.tsx\",\"import\":\"\",\"arguments\":[{\"variable\":\"--font-stolzl\",\"src\":[{\"path\":\"../../public/fonts/Stolzl-Regular.ttf\",\"weight\":\"400\"},{\"path\":\"../../public/fonts/Stolzl-Medium.ttf\",\"weight\":\"500\"}]}],\"variableName\":\"stolzl\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"app/[locale]/layout.tsx\",\"import\":\"\",\"arguments\":[{\"variable\":\"--font-loew\",\"src\":\"../../public/fonts/Loew-Heavy.otf\",\"preload\":false}],\"variableName\":\"loew\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/logo.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/thumbnail.png");
